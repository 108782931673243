import "../styles/css/Services.css";
import React from "react";
import imgServices1 from "../media/17.png";
import imgServices2 from "../media/15.png";
import imgServices3 from "../media/16.png";
import imgServices4 from "../media/18.png";
import imgServices5 from "../assets/6.jpg";
import imgServices6 from "../assets/8.jpg";
import { IconContext } from "react-icons";
import { BsCheck } from "react-icons/bs";
import WOW from "wowjs";
import { useEffect } from "react";

// import imgChose from "../media/4.png";
const Services = () => {
  useEffect(() => {
    new WOW.WOW({ live: false, mobile: true }).init();
  }, []);
  return (
    <>
      <div className="container-services-build container text-center  ">
        <div className="row title-content"></div>
        <div className="col-6 offset-3 col-sm-6 offset-sm-3 col-md-6 offset-md-3 ">
          <h2 className="text-color wow bounceInDown" data-wow-duration="2s">
            Services
          </h2>
        </div>
        <div className=" row  col-10 offset-1 col-sm-10 offset-sm-1 col-md-10 offset-md-1  col-lg-10 offset-md-1 ">
          <div
            className=" pb-2 mb-2"
            style={{ borderBottom: "3px solid #1c1f55" }}
          ></div>
        </div>
      </div>
      <IconContext.Provider
        value={{ color: "#E68719", className: "global-class-name" }}
      >
        <div className="container container-fluid container-services mt-2">
          <div className="row">
            <div className="col">
              <div
                className="container container-comercial mb-5 mt-5 fs-3 "
                id="servicesComercial"
              >
                <div className="row">
                  <div className="col-lg-6 col-sm-12 d-flex justify-content-center">
                    <div className="fund-services d-flex  position-absolute">
                      <div className="wow zoomIn" data-wow-duration="2s">
                        <img
                          src={imgServices1}
                          alt="services cleaning"
                          style={{ width: "100%" }}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="d-flex align-items-center ">
                        <div>
                          <img src={imgServices2} alt="services cleaning" />
                        </div>
                        <p className="fs-2 text-color">
                          Commercial Cleaning Services{" "}
                        </p>
                      </div>
                      <ul
                        className="ms-lg-5 ms-2 text-colorprin"
                        style={{ listStyle: "none" }}
                      >
                        <li className=" m-lg-3">
                          <BsCheck /> Office cleaning
                        </li>
                        <li className=" m-lg-3">
                          <BsCheck /> Medical cleaning
                        </li>
                        <li className=" m-lg-3">
                          <BsCheck /> After construction cleaning
                        </li>
                        <li className=" m-lg-3">
                          <BsCheck /> Church cleaning
                        </li>
                        <li className=" m-lg-3">
                          <BsCheck /> School cleaning
                        </li>
                        <li className=" m-lg-3">
                          <BsCheck /> And more
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12 d-flex align-items-center">
                    <div
                      className="row p-5   wow bounceInLeft "
                      data-wow-duration="2s"
                    >
                      <img
                        src={imgServices6}
                        alt="services cleaning"
                        style={{ borderRadius: "10%" }}
                      />
                    </div>{" "}
                  </div>
                </div>
              </div>

              <div
                className="container container-recidencial mb-5 mt-5 fs-3"
                id="servicesResidential"
              >
                <div className="row">
                  <div className="d-flex align-items-center justify-content-center">
                    <div>
                      <img src={imgServices3} alt="services cleaning" />
                    </div>
                    <p className="fs-2 text-color">
                      Residential Cleaning Services
                    </p>
                  </div>
                </div>
                <div className="row ">
                  <div className="col-lg-6 col-sm-12 d-flex justify-content-center">
                    <div className=" fund-services d-flex d-flex position-absolute">
                      <div className="  wow zoomIn" data-wow-duration="2s">
                        <img
                          src={imgServices4}
                          alt="services cleaning"
                          style={{ width: "100%" }}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="d-flex align-items-center ">
                        <p className="fs-2 text-color">Regular cleaning</p>
                      </div>
                      <ul
                        className="ms-lg-5 ms-2 text-colorprin"
                        style={{ listStyle: "none" }}
                      >
                        <li className=" m-lg-3">
                          <BsCheck /> Take out the trash
                        </li>
                        <li className=" m-lg-3">
                          <BsCheck /> Aspire
                        </li>
                        <li className=" m-lg-3">
                          <BsCheck /> Mop
                        </li>
                        <li className=" m-lg-3">
                          <BsCheck />
                          Remove dust
                        </li>
                        <li className=" m-lg-3">
                          <BsCheck /> Microwave cleaning
                        </li>
                        <li className=" m-lg-3">
                          <BsCheck /> Refrigerator cleaning
                        </li>
                        <li className=" m-lg-3">
                          <BsCheck /> Bathroom cleaning
                        </li>
                        <li className=" m-lg-3">
                          <BsCheck /> Kitchen cleaning
                        </li>
                        <li className=" m-lg-3">
                          <BsCheck /> And more
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12 d-flex justify-content-center">
                    <div className=" fund-services d-flex d-flex position-absolute ">
                      <div className="wow zoomIn" data-wow-duration="2s">
                        <img
                          src={imgServices4}
                          alt="services cleaning"
                          style={{ width: "100%" }}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="d-flex align-items-center ">
                        <p className="fs-2 text-color">Deep cleaning</p>
                      </div>
                      <ul
                        className="ms-lg-5 ms-2 text-colorprin"
                        style={{ listStyle: "none" }}
                      >
                        <li className=" m-lg-3">
                          <BsCheck /> Aspire
                        </li>
                        <li className=" m-lg-3">
                          <BsCheck /> Mop
                        </li>
                        <li className=" m-lg-3">
                          <BsCheck /> Remove dust
                        </li>
                        <li className=" m-lg-3">
                          <BsCheck />
                          Microwave cleaning
                        </li>
                        <li className=" m-lg-3">
                          <BsCheck /> Refrigerator cleaning
                        </li>
                        <li className=" m-lg-3">
                          <BsCheck /> Deep bathroom cleaning
                        </li>
                        <li className=" m-lg-3">
                          <BsCheck /> Deep kitchen cleaning
                        </li>
                        <li className=" m-lg-3">
                          <BsCheck /> And more
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-12">
                    <div
                      className="row p-5 justify-content-center wow bounceInDown"
                      data-wow-duration="2s"
                    >
                      <img
                        src={imgServices5}
                        alt="services cleaning"
                        style={{ width: "600px", borderRadius: "10%" }}
                      />
                    </div>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </IconContext.Provider>
    </>
  );
};

export default Services;
