import "../styles/css/Slider.css";
import { React, useEffect } from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { IconContext } from "react-icons";
import { BsTelephoneFill } from "react-icons/bs";
import { motion } from "framer-motion";
import WOW from "wowjs";
import Video from "../media/video.mp4";
import img12 from "../assets/12.jpg";
import img13 from "../assets/13.jpg";
import Carousel from "react-bootstrap/Carousel";

const Sliders = () => {
  useEffect(() => {
    new WOW.WOW({
      live: false,
      mobile: true,
    }).init();
  }, []);
  return (
    <div className="container_builder position-relative">
      <IconContext.Provider
        value={{ color: "white", className: "global-icons-home" }}
      >
        <div className="section_module position-absolute">
          <div className="section_image ratio ratio-16x9">
            <video
              src={Video}
              autoPlay
              loop
              muted
              className="z-depth-1"
              style={{ width: "100%", height: "100%" }}
            />

            <Carousel
              className="z-depth-1 d-sm-none d-block  d-lg-none d-xl-none align-middle "
              indicators={false}
              controls={false}
              style={{ marginTop: "100px" }}
            >
              <Carousel.Item className="align-middle">
                <img
                  className="d-block w-100 rounded-4"
                  src={img12}
                  alt="img Mobile"
                  style={{ height: "100vh" }}
                />
              </Carousel.Item>
              <Carousel.Item className="align-middle">
                <img
                  className="d-block w-100 rounded-4"
                  src={img13}
                  alt="img Mobile"
                  style={{ height: "100vh" }}
                />
              </Carousel.Item>
            </Carousel>
          </div>
          <div className="container_content_header position-absolute top-50 start-50 translate-middle ">
            <div className="header_content text-center row">
              <h1
                className="col-12  align-self-center fs-1 fw-bold wow pulse"
                data-wow-duration="1s"
                data-wow-iteration="infinite"
              >
                20 YEARS OF EXPERIENCE
              </h1>
              <div className="container_icon_button col-12 align-self-center container text-center">
                <div className="row">
                  <div className=" col-12 align-self-center ">
                    <svg className="arrows align-self-center">
                      <path className="a1" d="M0 0 L30 32 L60 0"></path>
                      <path className="a2" d="M0 20 L30 52 L60 20"></path>
                      <path className="a3" d="M0 40 L30 72 L60 40"></path>
                    </svg>
                  </div>
                  <motion.div
                    className="box col-12  align-self-center container-btn "
                    whileHover={{ scale: 1.2 }}
                    whileTap={{ scale: 0.9 }}
                    transition={{ type: "spring", stiffness: 400, damping: 17 }}
                  >
                    <Link className="sm-p-1" to="/contact">
                      <Button className="box btn-lg bg-transparent border border-4 rounded-3 m-2 md-p-2  fs-4 fw-bold">
                        <b className="container-btn">CONTACT US</b>
                        <BsTelephoneFill className="btn-icon" />
                      </Button>
                    </Link>
                  </motion.div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </IconContext.Provider>
    </div>
  );
};

export default Sliders;
