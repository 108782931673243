import "../styles/css/Home.css";
import { React, useEffect } from "react";
import imgSectionEstimates from "../assets/1.jpg";
import imgSectionQuiality from "../assets/2.jpg";
import imgSectionSurrouding from "../assets/3.jpg";
import Sliders from "./Sliders";
import cleanImg from "../media/26.PNG";
import imgChose from "../assets/4.jpg";
import imgCommitted from "../media/5.png";
import imgCustomer from "../media/6.png";
import imgWorckProcess1 from "../media/7.png";
import imgWorckProcess2 from "../media/8.png";
import imgWorckProcess3 from "../media/9.png";
import imgRequest1 from "../media/10.png";
import imgRequest2 from "../media/11.png";
import imgRequest3 from "../assets/5.jpg";
import imgRequest4 from "../assets/14.png";
import imgRequest5 from "../assets/15.png";
import imgServices1 from "../media/17.png";
import imgServices2 from "../media/15.png";
import imgServices3 from "../media/16.png";
import imgServices4 from "../media/18.png";
import imgServices5 from "../assets/6.jpg";
import imgServices6 from "../assets/8.jpg";
import { IconContext } from "react-icons";
import { BsCheck } from "react-icons/bs";
import WOW from "wowjs";

const Home = () => {
  useEffect(() => {
    new WOW.WOW({
      live: false,
      mobile: true,
    }).init();
  }, []);
  const cardsServices = [
    { id: 1, img: imgSectionEstimates },
    { id: 2, img: imgSectionQuiality },

    { id: 3, img: imgSectionSurrouding },
  ];

  return (
    <>
      <Sliders />
      <div className="container container-section-content ">
        <div className="row title-content d-felx justify-content-center">
          <div className="col-auto">
            <h2 className="">Welcome to </h2>
            <h2 className="ms-4">We Go</h2>
            <h2 className="ms-5">Cleaning LLC</h2>
          </div>
        </div>
        <div className="row">
          <div className="element-container fs-3 ">
            <p>
              Our goal is to serve with the quality, detail and commitment you
              deserve.
            </p>
            <p>
              We have more than 20 years of experience working in different
              retail, commercial and Residential. We work hard with extensive
              knowledge in cleaning.
            </p>
            <p>
              Any other service you require, please ask me in my contact form.
            </p>
          </div>
        </div>
        <div className="row ">
          {cardsServices.map((CardService) => {
            return (
              <div key={CardService.id} className="col-md-6 col-lg-4 p-5 ">
                <div className="wow bounceInDown" data-wow-duration="2s ">
                  <img
                    src={CardService.img}
                    alt="cleaning and services"
                    style={{ borderRadius: "10%", width: "100%" }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="bg-line-borde ">
        <div className="difference-text ">
          <div
            className="container-lyrics-k ms-5 "
            style={{ fontSize: "130px" }}
          >
            K
          </div>
          <div className="difference-subtext container-text">
            <b className="fs-1">now</b>
            <b className="fs-1">the difference</b>
          </div>
        </div>
        <div className="difference-text container-item-diference col p-4 fs-3">
          <div className="row item-difference">
            ~ Sanitizing reduces the bacteria identified on the product’s label
            on surface and laundry.
          </div>
          <div className="row item-difference">
            ~ Disinfecting destroys or inactivates both the bacteria and viruses
            identified on the product’s label (like influenza and rhinovirus) on
            hard, nonporous surfaces.
          </div>
          <div className="row item-difference">
            ~ Cleaning removes dust, debris and dirt from surface by scrubbing,
            washing and rinsing.
          </div>
        </div>
      </div>
      <div className="container  mt-5">
        <div className="row">
          <div className="col-lg   container-choose fs-3 mb-3">
            <div className="row d-flex justify-content-center mb-4 ">
              <div className=" d-flex justify-content-center ">
                <img
                  src={cleanImg}
                  alt="cleaning instrument"
                  className="wow rubberBand"
                  data-wow-iteration="infinite"
                />
              </div>
              <b className=" d-flex justify-content-center fs-2">
                __Why choose us?__
              </b>
            </div>
            <p>
              We Go Cleaning LLC It is a company dedicated to residential and
              commercial cleaning.
            </p>
            <p>
              3 years ago we started operations with the mission of helping
              people who do not find the time in their week to make your home,
              condominium or office look impeccable.
            </p>
          </div>
          <div className=" col d-flex align-items-center justify-content-center">
            <div
              className=" d-felx justify-content-center wow fadeInLeft"
              data-wow-delay="1s"
            >
              <img
                style={{ borderRadius: "10%", width: "100%" }}
                src={imgChose}
                alt="residential cleaning"
              />
            </div>
          </div>
        </div>
        <div className="row mt-5 mb-5" style={{ color: "$colorprin" }}>
          <div className="col-lg ">
            <div className="row ">
              <div className="container col-lg-2 d-flex justify-content-center ">
                <img src={imgCommitted} alt="committed" />
              </div>
              <div className=" col-lg-10 mt-3 ">
                <p className="fs-2 title  d-flex justify-content-center ">
                  We are committed
                </p>
                <p className="fs-5 element-container d-flex justify-content-center">
                  Our work is 100% guaranteed
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg">
            <div className="row">
              <div className="d-inline col-lg-2  d-flex justify-content-center">
                <img src={imgCustomer} alt="customer" />
              </div>
              <div className="d-inline col-lg-10 ">
                <p className="fs-2 title  d-flex justify-content-center ">
                  Customer Focused Reviews
                </p>
                <p className="fs-5 col-lg-8   element-container  d-flex justify-content-center ms-5">
                  Our job is to serve you and that you are satisfied at all
                  times
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="container container-fluid container-work_process ">
          <div className="row">
            <div className="col">
              <div className="row">
                <div className="col-4 mt-3 divider-work_process "></div>
                <div className="col-4 ">
                  <p className=" title-work_process  d-flex justify-content-center">
                    WORK PROCESS
                  </p>
                </div>
                <div className=" col-4 mt-3   divider-work_process d-inline-flex"></div>
              </div>
              <div className="container mt-3">
                <div className="col align-self-center d-flex justify-content-center">
                  <p className="text-work_process fs-1 d-block">
                    Some Easy Steps <br /> to Clean Your Asset
                  </p>
                </div>
              </div>
              <div className="container mt-5">
                <div className="row ">
                  <div className="col-lg-6 d-flex justify-content-center">
                    <img
                      src={imgWorckProcess1}
                      alt="WorckProcess"
                      style={{ width: "150px" }}
                      className="wow pulse"
                      data-wow-iteration="infinite"
                    />
                  </div>
                  <div className="col-lg-6 d-lg-flex align-items-center">
                    <div className="justify-content-center">
                      <h2 className="d-flex justify-content-center justify-content-lg-start text-color">
                        Easy Online Booking
                      </h2>
                      <p className="d-flex justify-content-sm-center justify-content-lg-start text-colorprin fs-4">
                        Contact us and ask about our promotions
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container mt-5">
                <div className="row ">
                  <div className="col-lg-6 d-flex justify-content-center">
                    <img
                      src={imgWorckProcess2}
                      alt="WorckProcess"
                      style={{ width: "150px" }}
                      className="wow pulse"
                      data-wow-iteration="infinite"
                    />
                  </div>
                  <div className="col-lg-6 d-lg-flex align-items-center">
                    <div className="justify-content-center ">
                      <h2 className="d-flex justify-content-center justify-content-lg-start text-color">
                        Get a cleaner
                      </h2>
                      <p className="d-flex justify-content-sm-center justify-content-lg-start text-colorprin fs-4">
                        Our staff will visit you and do the cleaning
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container mt-5">
                <div className="row ">
                  <div className="col-lg-6 d-flex justify-content-center">
                    <img
                      src={imgWorckProcess3}
                      alt="WorckProcess"
                      style={{ width: "150px" }}
                      className="wow pulse"
                      data-wow-iteration="infinite"
                    />
                  </div>
                  <div className="col-lg-6 d-lg-flex align-items-center">
                    <div className="justify-content-center">
                      <h2 className="d-flex justify-content-center justify-content-lg-start text-color ">
                        Enjoy Cleanliness
                      </h2>
                      <p className="d-flex justify-content-center justify-content-lg-start text-colorprin fs-4">
                        Our work is 100% guaranteed
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-line-borde mt-5 ">
        <div className="row">
          <div className="col-lg col-md row-sm bg-inline ">
            <div className="difference-text ms-5">
              <div
                className="container-lyrics-k ms-5 "
                style={{ fontSize: "150px" }}
              >
                R
              </div>
              <div className="difference-subtext container-text">
                <b className="fs-1">EQUEST</b>
                <b className="fs-1">A QOUTE</b>
              </div>
            </div>
          </div>
          <div className="col ">
            <div className="row mt-4 ms-4">
              <p style={{ fontSize: "25px", color: "white" }}>
                Scheduling options to fit your needs
              </p>
            </div>
            <div className="row">
              <div className="col d-flex">
                <div>
                  <img
                    src={imgRequest1}
                    alt="message logo"
                    style={{ width: "80px" }}
                  />
                </div>
                <div className="row align-items-center">
                  <p
                    style={{ fontSize: "25px", color: "white" }}
                    className="mt-3"
                  >
                    Send us a message
                  </p>
                </div>
              </div>
            </div>
            <div className="row ms-2 mb-4">
              <div className="col d-flex">
                <div className="">
                  <img
                    src={imgRequest2}
                    alt="phone logo"
                    style={{ width: "50px" }}
                  />
                </div>
                <div className="row align-items-center ms-1">
                  <p style={{ fontSize: "25px", color: "white" }}>Call us</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-5">
        <div className="row">
          <div className="col-lg   container-choose fs-3 mb-3 mt-5">
            <p>Let us clean for you</p>
            <p>
              All our collaborators stand for the honesty and professionalism
              with which they cary out ench task, you can trust us.
            </p>
            <p>Offering quality cleaning services at affordable prices</p>
          </div>
          <div className=" col d-flex align-items-center  justify-content-center">
            <div className="row d-flex justify-content-center ">
              <div
                className=" d-felx justify-content-center wow fadeInLeft"
                data-wow-delay="1s"
              >
                <img
                  className="wow backInLeft"
                  src={imgRequest3}
                  alt="Let us clean for you"
                  style={{ width: "100%", borderRadius: "10%" }}
                />
              </div>
            </div>
          </div>
        </div>
        <div style={{ marginTop: "100px" }}>
          <div className="row ">
            <div className="col-sm-12 col-lg row-sm d-none d-lg-block ">
              <div
                className="d-flex justify-content-center wow  bounceInRight"
                data-wow-duration="2s"
              >
                <img
                  src={imgRequest4}
                  alt=" quality of the service "
                  style={{ width: "400px" }}
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="row-sm-12 col-lg   container-choose fs-3 mb-3  d-flex align-items-center">
              <p>
                Because we know that the quality of the service makes the
                difference the safety of our employees and clients is our
                priority. <br></br>Let us help you determine Your cleaning needs
              </p>
            </div>
            <div className=" col-lg  col-sm-12 row-sm d-lg-none ">
              <div className=" d-flex justify-content-center ">
                <div className="wow  bounceInRight" data-wow-duration="2s">
                  <img
                    src={imgRequest4}
                    alt=" quality of the service "
                    className="img-fluid"
                    style={{ width: "400px" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style={{ marginTop: "100px" }}>
          <div className="row">
            <div className="col-lg   container-choose fs-3 mb-3 mt-5 align-center">
              <p>
                We know that the quality of the services makes the difference,
                and that is why we use antimicrobial products and solutions that
                eliminate all kinds of germs, let us help you determine your
                cleaning needs
              </p>
            </div>
            <div className="container col ">
              <div
                className="row d-flex justify-content-center wow bounceInLeft"
                data-wow-duration="2s"
              >
                <img
                  src={imgRequest5}
                  alt="quality of the service"
                  style={{ width: "400px" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <IconContext.Provider
        value={{ color: "#E68719", className: "global-class-name" }}
      >
        <div className="container container-fluid container-services mt-5">
          <div className="row">
            <div className="col">
              <div className="row p-3">
                <div className="col-4 mt-3 divider-work_process "></div>
                <div className="col-4 ">
                  <p className=" title-work_process  d-flex justify-content-center">
                    OUR SERVICES
                  </p>
                </div>
                <div className=" col-4 mt-3   divider-work_process d-inline-flex"></div>
              </div>

              <div className="container container-comercial mb-5 mt-5 fs-3">
                <div className="">
                  <div className="col-lg-6 col d-flex justify-content-center">
                    <div className=" fund-services d-flex position-absolute">
                      <div className="wow zoomIn" data-wow-duration="2s">
                        <img
                          src={imgServices1}
                          alt="commercial cleaning"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                    <div>
                      <div className="d-flex align-items-center ">
                        <div>
                          <img src={imgServices2} alt="commercial cleaning" />
                        </div>
                        <p className="fs-2 text-color">
                          Commercial Cleaning Services{" "}
                        </p>
                      </div>
                      <ul
                        className="ms-lg-5 ms-2 text-colorprin"
                        style={{ listStyle: "none" }}
                      >
                        <li className=" m-lg-3">
                          <BsCheck /> Office cleaning
                        </li>
                        <li className=" m-lg-3">
                          <BsCheck /> Medical cleaning
                        </li>
                        <li className=" m-lg-3">
                          <BsCheck /> After construction cleaning
                        </li>
                        <li className=" m-lg-3">
                          <BsCheck /> Church cleaning
                        </li>
                        <li className=" m-lg-3">
                          <BsCheck /> School cleaning
                        </li>
                        <li className=" m-lg-3">
                          <BsCheck /> And more
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12 d-flex align-items-center">
                    <div
                      className="row p-5 wow bounceInLeft"
                      data-wow-duration="2s"
                    >
                      <img
                        src={imgServices6}
                        alt="commercial cleaning"
                        style={{ borderRadius: "10%" }}
                      />
                    </div>{" "}
                  </div>
                </div>
              </div>

              <div className="container container-recidencial mb-5 mt-5 fs-3">
                <div className="row">
                  <div className="d-flex align-items-center justify-content-center">
                    <div>
                      <img
                        src={imgServices3}
                        alt="Residential Cleaning Services"
                      />
                    </div>
                    <p className="fs-2 text-color">
                      Residential Cleaning Services
                    </p>
                  </div>
                </div>
                <div className="row ">
                  <div className="col-lg-6 col-sm-10 d-flex justify-content-center">
                    <div className="fund-services  d-flex position-absolute">
                      <div className=" wow zoomIn" data-wow-duration="2s">
                        <img
                          src={imgServices4}
                          alt="Residential Cleaning Services"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                    <div>
                      <div className="d-flex align-items-center ">
                        <p className="fs-2 text-color">Regular cleaning</p>
                      </div>
                      <ul
                        className="ms-lg-5 ms-2 text-colorprin"
                        style={{ listStyle: "none" }}
                      >
                        <li className=" m-lg-3">
                          <BsCheck /> Take out the trash
                        </li>
                        <li className=" m-lg-3">
                          <BsCheck /> Aspire
                        </li>
                        <li className=" m-lg-3">
                          <BsCheck /> Mop
                        </li>
                        <li className=" m-lg-3">
                          <BsCheck />
                          Remove dust
                        </li>
                        <li className=" m-lg-3">
                          <BsCheck /> Microwave cleaning
                        </li>
                        <li className=" m-lg-3">
                          <BsCheck /> Refrigerator cleaning
                        </li>
                        <li className=" m-lg-3">
                          <BsCheck /> Bathroom cleaning
                        </li>
                        <li className=" m-lg-3">
                          <BsCheck /> Kitchen cleaning
                        </li>
                        <li className=" m-lg-3">
                          <BsCheck /> And more
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12 d-flex justify-content-center">
                    <div className="fund-services d-flex d-flex position-absolute ">
                      <div className="wow zoomIn" data-wow-duration="2s">
                        <img
                          src={imgServices4}
                          alt="Residential Cleaning Services"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                    <div>
                      <div className="d-flex align-items-center ">
                        <p className="fs-2 text-color">Deep cleaning</p>
                      </div>
                      <ul
                        className="ms-lg-5 ms-2 text-colorprin"
                        style={{ listStyle: "none" }}
                      >
                        <li className=" m-lg-3">
                          <BsCheck /> Aspire
                        </li>
                        <li className=" m-lg-3">
                          <BsCheck /> Mop
                        </li>
                        <li className=" m-lg-3">
                          <BsCheck /> Remove dust
                        </li>
                        <li className=" m-lg-3">
                          <BsCheck />
                          Microwave cleaning
                        </li>
                        <li className=" m-lg-3">
                          <BsCheck /> Refrigerator cleaning
                        </li>
                        <li className=" m-lg-3">
                          <BsCheck /> Deep bathroom cleaning
                        </li>
                        <li className=" m-lg-3">
                          <BsCheck /> Deep kitchen cleaning
                        </li>
                        <li className=" m-lg-3">
                          <BsCheck /> And more
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-12">
                    <div
                      className="row p-5 justify-content-center wow bounceInDown"
                      data-wow-duration="2s"
                    >
                      <img
                        src={imgServices5}
                        alt="Residential Cleaning Services"
                        style={{ width: "600px", borderRadius: "10%" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </IconContext.Provider>
    </>
  );
};
export default Home;
