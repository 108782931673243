import "../styles/css/Footer.css";
import React from "react";
import social from "../media/25.png";
import WOW from "wowjs";
import { useEffect } from "react";
import { AiOutlineCopyrightCircle } from "react-icons/ai";
import { BsFillClockFill } from "react-icons/bs";
import { IoLocation } from "react-icons/io5";

const FooterUtil = () => {
  useEffect(() => {
    new WOW.WOW({ live: false, mobile: true }).init();
  }, []);
  return (
    <div className="container-fluid footer  bg-line-borde ">
      <div className="row">
        <div className="col  d-flex flex-column justify-content-center align-items-center">
          <div
            className=" wow heartBeat"
            data-wow-iteration="infinite"
            data-wow-delay="1s"
            data-wow-duration="2s"
          >
            <b className="d-block fs-4 text-white  align-middle ms-3">
              Let us clean for you{" "}
            </b>
          </div>
          <div className="row mt-5">
            <div className="d-flex justify-content-center">
              <BsFillClockFill size={"20px"} color={"#84ff00"} />
            </div>
            <div className="item-menu d-flex justify-content-center">
              <b>OFFICE HOURS</b>
            </div>
            <div className="item-menu d-flex justify-content-center">
              <p className=" d-flex justify-content-center">
                Monday to Friday from
                <br /> 08:00 a.m. to 04:00 p.m.
              </p>
            </div>
          </div>
        </div>
        <div className="col mt-3 ">
          <div className="row">
            <div className="container-social">
              <b className="d-flex justify-content-center">SOCIAL NETWORKS</b>
            </div>
            <div className="container-social-img d-flex justify-content-center">
              <img src={social} alt="social logo" />
            </div>
          </div>
          <div className="row  ">
            <div className="d-flex justify-content-center">
              <div className="d-flex align-items-center">
                <IoLocation size={"20px"} color={"#84ff00"} />
              </div>
              <p className="container-location mt-4">
                We Cover All Madison and Surrounding Areas
              </p>
            </div>
          </div>
          <div className="mt-1  mb-3 d-flex justify-content-center">
            <AiOutlineCopyrightCircle size={"20px"} color={"#84ff00"} />
            <h2 className="fs-6 ms-2 text-white ">
              Copyright all rights reserved
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterUtil;
