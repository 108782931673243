import "../styles/css/AboutUs.css";
import { React, useEffect } from "react";
import imgAbout from "../assets/7.jpg";
import imgAbout1 from "../assets/14.png";
import imgAbout2 from "../assets/15.png";
import WOW from "wowjs";

const AboutUs = () => {
  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, []);
  return (
    <div>
      <div className="container">
        <div
          className="container-contact-build container container-fluid text-center  "
          name="aboutUs"
        >
          <div
            className="col-6 offset-3 col-sm-6 offset-sm-3 col-md-6 offset-md-3 text-center"
            name="aboutRef"
          >
            <h2 className="text-color wow bounceInDown" data-wow-duration="2s">
              About Us
            </h2>
          </div>
          <div className=" ">
            <div
              className=" pb-2 mb-4 "
              style={{ borderBottom: "3px solid #1c1f55" }}
            ></div>
          </div>
        </div>

        <div className="row ">
          <div className="">
            <p className="fs-4 text-colorprin d-flex justify-content-center mt-3">
              We Go Cleaning LLC it is a company dedicated to residential and
              commercial cleaning. 3 years ago we started operations with the
              mission of helping people who do not find the time in their week
              to make your home, condominium or office look impeccable.
            </p>
          </div>
        </div>

        <div className="row mt-5 mb-4">
          <div className="col-lg-6 row-sm d-flex align-items-center">
            <div>
              <p className="fs-4 text-colorprin ">
                Our goal is to serve with the quality, detail and commitment you
                deserve. We have more than 20 years of experience working in
                different retail, commercial and Residential. We work hard with
                extensive knowledge in cleaning.
              </p>
              <p className="fs-4 text-colorprin">
                Any other service you require, please ask me in my contact form.
              </p>
            </div>
          </div>
          <div className="col-lg-6 row-sm">
            <div className="row">
              <div className=" d-flex align-items-center justify-content-center">
                <div className="wow fadeInLeft" data-wow-delay="1s">
                  <img
                    src={imgAbout}
                    alt="aboutImage Cleaning services"
                    style={{ width: "100%", borderRadius: "10%" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row   mb-4" style={{ marginTop: "100px" }}>
          <div className="col-lg">
            <div
              className="row d-flex justify-content-center wow bounceInUp"
              data-wow-duration="2s"
            >
              <img
                src={imgAbout1}
                alt="aboutImage Cleaning services"
                style={{ width: "300px" }}
              />
            </div>
            <div className="row mt-4">
              <p className="fs-4 text-colorprin text-justify">
                Becuse we know that the quality of the service makes the
                difference the safety of our employees and clients is our
                priority. Let us help you determine your cleaning needs.
              </p>
            </div>
          </div>
          <div className="col-lg">
            <div
              className="row  d-flex justify-content-center wow bounceInUp"
              data-wow-duration="2s"
            >
              <img
                src={imgAbout2}
                alt="aboutImage Cleaning services"
                style={{ width: "300px" }}
              />
            </div>
            <div className="row mt-4">
              <p className="fs-4 text-colorprin text-justify">
                We know that the quality of the services makes the difference,
                and that is why we use animicrobial products and solutions that
                eliminate all kinds of germs, let us help you determine your
                cleaning.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
