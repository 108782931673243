import "../styles/css/NavabarUtils.css";
import { React } from "react";
import Nav from "react-bootstrap/Nav";
import logo from "../assets/logotipo.png";
import Navbar from "react-bootstrap/Navbar";
import { NavLink } from "react-router-dom";
import { BsFillTelephoneFill, BsFillEnvelopeFill } from "react-icons/bs";

const NavbarUtil = () => {
  return (
    <div className="fixed-top nav-container">
      <div className="">
        <div className="col-sm-12  offset-sm-12 col-md-6 offset-md-6 col-lg-6 offset-lg-6  d-md-flex d-sm-flex  justify-content-center">
          <div className="mt-2 d-block align-middle d-flex  justify-content-center">
            <div>
              <BsFillEnvelopeFill
                className="d-inline-flex align-middle "
                color={"#84ff00"}
              />
              <span className="d-inline-flex align-middle text-white ms-2">
                we.go.cleaning.llc@gmail.com
              </span>
            </div>
          </div>
          <div className="ms-lg-3 mt-md-2 mt-lg-2 d-block align-middle  d-flex  justify-content-center">
            <a href="tel:(608) 332 9221">
              <BsFillTelephoneFill
                className=" wow tada d-inline-flex align-middle"
                data-wow-duration="2s"
                data-wow-iteration="infinite"
                color={"#84ff00"}
              />
              <span className="d-inline-flex align-middle text-white ms-2">
                (608) 332-9221
              </span>
            </a>
          </div>
        </div>
      </div>
      <Navbar
        collapseOnSelect
        expand="lg"
        className="fs-5"
        variant="dark"
        sticky="top"
      >
        <Navbar.Brand
          as={NavLink}
          to="/"
          data-bs-toggle="collapse"
          href="#responsive-navbar-nav"
        >
          <img src={logo} alt="wegocleaning logo" className="img_Logo " />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          className="border border-0"
        />
        <Navbar.Collapse
          id="responsive-navbar-nav"
          className="justify-content-end "
        >
          <Nav className="float-md-start ms-3">
            <Nav.Link
              to="/"
              as={NavLink}
              end
              className="me-5 "
              data-bs-toggle="collapse"
              href="#responsive-navbar-nav"
              style={({ isActive }) => ({
                color: isActive ? "white" : "#d3d3cb",
                display: isActive ? "none" : "",
                borderBottom: isActive ? "2px solid white " : "none",

                listStyle: "none",
                textDecoration: "none",
              })}
            >
              HOME
            </Nav.Link>

            <Nav.Link
              to="/about"
              as={NavLink}
              data-bs-toggle="collapse"
              href="#responsive-navbar-nav"
              className="me-5"
              style={({ isActive }) => ({
                color: isActive ? "white" : "#d3d3cb",
                borderBottom: isActive ? "2px solid white " : "none",
                listStyle: "none",
                textDecoration: "none",
              })}
            >
              ABOUT US
            </Nav.Link>

            <Nav.Link
              as={NavLink}
              to="/services"
              className="me-5"
              data-bs-toggle="collapse"
              href="#responsive-navbar-nav"
              style={({ isActive }) => ({
                color: isActive ? "white" : "#d3d3cb",
                borderBottom: isActive ? "2px solid white " : "none",
                listStyle: "none",
                textDecoration: "none",
              })}
            >
              SERVICES
            </Nav.Link>

            <Nav.Link
              as={NavLink}
              to="/gallery"
              className="me-5"
              data-bs-toggle="collapse"
              href="#responsive-navbar-nav"
              style={({ isActive }) => ({
                color: isActive ? "white" : "#d3d3cb",
                borderBottom: isActive ? "2px solid white " : "none",
                listStyle: "none",
                textDecoration: "none",
              })}
            >
              GALLERY
            </Nav.Link>
            <Nav.Link
              as={NavLink}
              to="/contact"
              className="me-5"
              data-bs-toggle="collapse"
              href="#responsive-navbar-nav"
              style={({ isActive }) => ({
                color: isActive ? "white" : "#d3d3cb",
                borderBottom: isActive ? "2px solid white " : "none",
                listStyle: "none",
                textDecoration: "none",
              })}
            >
              CONTACT US
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default NavbarUtil;
