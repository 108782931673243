import "../styles/css/ContactUs.css";
import { React, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import ModalEmail from "../components/alertEmail";
import {
  BsFillTelephoneFill,
  BsFillEnvelopeFill,
  BsFillCheckCircleFill,
} from "react-icons/bs";
import { IoLocation, IoWarning } from "react-icons/io5";
import { IconContext } from "react-icons";
import emailjs from "@emailjs/browser";
import { useRef } from "react";
import WOW from "wowjs";
import { useEffect } from "react";

const ContactUs = () => {
  useEffect(() => {
    new WOW.WOW({ live: false, mobile: true }).init();
  }, []);

  //states for input income
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);

  //  states for input validation
  const [isValidateName, setValidateName] = useState(false);
  const [isValidatePhone, setValidatePhone] = useState(false);
  const [isValidateEmail, setValidateEmail] = useState(false);
  const [isValidateMessage, setValidateMessage] = useState(false);
  //regular expretion email
  const re = /^([\da-z_.-]+)@([\da-z.-]+).([a-z.]{2,6})$/;
  const phoneRe = /^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$/;

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    if (
      isValidateEmail &
      isValidateMessage &
      isValidateName &
      isValidatePhone
    ) {
      emailjs
        .sendForm(
          
          "service_k4y3f7m",
          "template_3qwlpro",
          form.current,
          "j6fJy5y6-fIfp2Gd8"
          

        )
        .then(
          (result) => {
            console.log(result.text);
          },
          (error) => {
            console.log(error.text);
          }
        );
      handleShow();
      clearFields();
    }
  };

  const checkInputName = () => {
    //Check input Name
    if (name === "") {
      setValidateName(false);
    } else {
      setValidateName(true);
    }
  };
  const checkInputEmail = () => {
    //Check input email

    if (!re.exec(email)) {
      setValidateEmail(false);
    } else {
      setValidateEmail(true);
    }
  };
  const checkInputPhone = () => {
    //Check input Name
    if (!phoneRe.exec(phone)) {
      setValidatePhone(false);
    } else {
      setValidatePhone(true);
    }
  };
  const checkInputNameMessage = () => {
    //Check input Name
    if (message === "") {
      setValidateMessage(false);
    } else {
      setValidateMessage(true);
    }
  };

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const clearFields = () => {
    setName("");
    setEmail("");
    setPhone("");
    setMessage("");
  };

  return (
    <div>
      <div className="container-contact-build container container-fluid text-center">
        <div className="row title-content">
          <div className="col ">
            <h2 className="text-color wow bounceInDown " data-wow-duration="2s">
              Contact Us
            </h2>
            <div className=" row  col-10 offset-1 col-sm-10 offset-sm-2 col-md-10 offset-md-1 ">
              <div
                className=" pb-2 mb-4 "
                style={{ borderBottom: "3px solid #1c1f55" }}
              ></div>
            </div>
            <p className="text-colorprin fs-4">
              Contact us, do not wait any longer, we are the best in this, we
              are always at the service of our customers
            </p>
          </div>
        </div>
      </div>
      <div className="conatiner-contact container mb-5  ">
        <div className="row  justify-content-center">
          <div className=" col-lg-5 col-sm-12 me-lg-5">
            <Form className="align-self-start" ref={form}>
              <Form.Group
                className="mb-4 "
                controlId="exampleForm.ControlInput1"
              >
                <div className="d-flex   align-items-center">
                  <Form.Control
                    className="border-top inline"
                    type="text"
                    placeholder="Your name"
                    name="user_name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    onBlur={checkInputName}
                  />
                  {isValidateName ? (
                    <BsFillCheckCircleFill className="inline m-1 text-success" />
                  ) : (
                    ""
                  )}
                </div>
                {isValidateName ? (
                  ""
                ) : (
                  <div
                    className="alert alert-danger  text-danger "
                    role="alert"
                    style={{ width: "100%", height: "10px" }}
                  >
                    <div>
                      <IoWarning />
                      This name field cannot be empty!
                    </div>
                  </div>
                )}
              </Form.Group>

              <Form.Group
                className="mb-4"
                controlId="exampleForm.ControlInput1"
              >
                <div className="d-flex   align-items-center">
                  <Form.Control
                    type="email"
                    placeholder="Your email"
                    name="user_email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onBlur={checkInputEmail}
                  />
                  {isValidateEmail ? (
                    <BsFillCheckCircleFill className="inline m-1 text-success" />
                  ) : (
                    ""
                  )}
                </div>

                {isValidateEmail ? (
                  ""
                ) : (
                  <div
                    className="alert alert-danger  text-danger "
                    role="alert"
                    style={{ width: "100%" }}
                  >
                    <div>
                      <IoWarning />
                      Wrong email format!
                    </div>
                  </div>
                )}
              </Form.Group>

              <Form.Group
                className="mb-4"
                controlId="exampleForm.ControlInput1"
              >
                <div className="d-flex   align-items-center">
                  <Form.Control
                    type="text"
                    placeholder="Your phone"
                    name="user_phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    onBlur={checkInputPhone}
                  />
                  {isValidatePhone ? (
                    <BsFillCheckCircleFill className="inline m-1 text-success" />
                  ) : (
                    ""
                  )}
                </div>
                {isValidatePhone ? (
                  ""
                ) : (
                  <div
                    className="alert alert-danger  text-danger "
                    role="alert"
                    style={{ width: "100%", height: "10px" }}
                  >
                    <div>
                      <IoWarning />
                      Enter a valid phone number!
                    </div>
                  </div>
                )}
              </Form.Group>

              <Form.Group
                className="mb-4"
                controlId="exampleForm.ControlTextarea1"
              >
                <div className="d-flex   align-items-center">
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Your message"
                    name="user_message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    onBlur={checkInputNameMessage}
                  />
                  {isValidateMessage ? (
                    <BsFillCheckCircleFill className="inline m-1 text-success" />
                  ) : (
                    ""
                  )}
                </div>
                {isValidateMessage ? (
                  ""
                ) : (
                  <div
                    className="alert alert-danger  text-danger "
                    role="alert"
                    style={{ width: "100%", height: "10px" }}
                  >
                    <div>
                      <IoWarning />
                      This message field cannot be empty!
                    </div>
                  </div>
                )}
              </Form.Group>
              <Button
                className="btn bg-divider border-divider text-white"
                type="submit"
                onClick={sendEmail}
              >
                Send
              </Button>
            </Form>
            <div className=" col-1 col-sm-2 col-md-3"></div>
          </div>
          <ModalEmail showModal={show} handleClose={handleClose} />
          <IconContext.Provider
            value={{ className: "IconsFooter", color: "#84ff00", size: "40px" }}
          >
            <div className="col-lg-2">
              <div className="row ">
                <div className="d-flex justify-content-center">
                  <IoLocation />
                </div>

                <div className="d-flex justify-content-center">
                  <p className=" d-flex justify-content-center text-colorprin">
                    We Cover All Madison and Surrounding Areas
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="d-flex justify-content-center ">
                  <a href="tel:(608) 332 9221">
                    <BsFillTelephoneFill
                      className=" wow tada"
                      data-wow-duration="2s"
                      data-wow-iteration="infinite"
                    />
                  </a>
                </div>
                <div className=" d-flex justify-content-center">
                  <b className="text-colorprin">PHONE</b>
                </div>
                <div className=" d-flex justify-content-center">
                  <p className="text-colorprin">(608) 332-9221</p>
                </div>
              </div>
              <div className="row">
                <div className="d-flex justify-content-center">
                  <BsFillEnvelopeFill />
                </div>
                <div className=" d-flex justify-content-center">
                  <b className="text-colorprin">E-MAIL </b>
                </div>
                <div className=" d-flex justify-content-center">
                  <p className="text-colorprin">we.go.cleaning.llc@gmail.com</p>
                </div>
              </div>
            </div>
          </IconContext.Provider>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
