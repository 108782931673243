import "./styles/css/App.css";
import Home from "./components/Home";
import AboutUs from "./components/AboutUs";
import Gallery from "./components/Gallery";
import Services from "./components/Services";
import ContactUs from "./components/ContactUs";
import { HashRouter, Routes, Route } from "react-router-dom";
import NavbarUtil from "./components/NavbarUtil";
import ScrollToTop from "./components/ScrollToTop";
import FooterUtil from "./components/Footer.util";

function App() {
  return (
    <div>
      <HashRouter>
        <ScrollToTop />
        <NavbarUtil />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/services" element={<Services />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/contact" element={<ContactUs />} />
        </Routes>
        <FooterUtil />
      </HashRouter>
    </div>
  );
}

export default App;
