import "../styles/css/Gallery.css";
import React from "react";
import Carousel from "react-bootstrap/Carousel";
import imgC1 from "../assets/1.jpg";
import imgC2 from "../assets/2.jpg";
import imgC3 from "../assets/3.jpg";
import imgC4 from "../assets/4.jpg";
import imgC5 from "../assets/5.jpg";
import imgC6 from "../assets/6.jpg";
import imgC7 from "../assets/7.jpg";
import imgC8 from "../assets/8.jpg";
import imgC11 from "../assets/11.jpg";

import WOW from "wowjs";
import { useEffect } from "react";

// import imgSlider from "../images/Slider_optional.jpg";
const Gallery = () => {
  useEffect(() => {
    new WOW.WOW({ live: false, mobile: true }).init();
  }, []);
  return (
    <>
      <div className="container-gallery-build container text-center ">
        <div className="row title-content"></div>
        <div className="col-6 offset-3 col-sm-6 offset-sm-3 col-md-6 offset-md-3 ">
          <h2 className="text-color wow bounceInDown" data-wow-duration="2s">
            Gallery
          </h2>
        </div>
        <div className=" row  col-10 offset-1 col-sm-10 offset-sm-1 col-md-10 offset-md-1  col-lg-10 offset-md-1 ">
          <div
            className=" pb-2 mb-4 "
            style={{ borderBottom: "3px solid #1c1f55" }}
          ></div>
        </div>
      </div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="content-gallery d-block col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-8 offset-lg-2 m-5">
            <Carousel>
              <Carousel.Item>
                <img
                  className="d-block w-100 rounded-4"
                  src={imgC1}
                  alt="gallery cleaning services"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100 rounded-4"
                  src={imgC2}
                  alt="gallery cleaning services"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100 rounded-4"
                  src={imgC3}
                  alt="gallery cleaning services"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100 rounded-4"
                  src={imgC4}
                  alt="gallery cleaning services"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100 rounded-4"
                  src={imgC5}
                  alt="gallery cleaning services"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100 rounded-4"
                  src={imgC6}
                  alt="gallery cleaning services"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100 rounded-4"
                  src={imgC7}
                  alt="gallery cleaning services"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100 rounded-4 "
                  src={imgC8}
                  alt="gallery cleaning services"
                />
              </Carousel.Item>

              <Carousel.Item>
                <img
                  className="d-block w-100 rounded-4"
                  src={imgC11}
                  alt="gallery cleaning services"
                />
              </Carousel.Item>
            </Carousel>
          </div>
        </div>
      </div>
    </>
  );
};

export default Gallery;
